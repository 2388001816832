import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import "./ColorButton.scss";

function ColorButton({
  fontSize,
  background,
  variant,
  outlinedColor,
  size,
  link,
  name,
}) {
  const ColorButton = withStyles((theme) =>
    variant === "contained"
      ? {
          root: {
            color: theme.palette.getContrastText("#000000"),
            fontWeight: 500,
            fontSize: fontSize,

            "&:hover": {
              fontWeight: 600,
              color: "#EFF3FE",
            },
          },
        }
      : {
          root: {
            color: outlinedColor,
            fontWeight: 500,
            fontSize: fontSize,
            border: "2px solid" + outlinedColor,

            "&:hover": {
              fontWeight: 600,
              color: "#EFF3FE",
              backgroundColor: outlinedColor,
            },
          },
        }
  )(Button);

  return (
    <ColorButton
      style={{ background: background }}
      variant={variant}
      size={size}
      href={link}
      className={variant === "contained" ? "color-btn" : "color-btn-outlined"}
    >
      {name}
    </ColorButton>
  );
}

export default ColorButton;
