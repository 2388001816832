import React, { useState, useEffect } from "react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { css } from "@emotion/core";

import "../../styles/swiper/swiper.scss";
import "../../styles/swiper/navigation.scss";

SwiperCore.use([Navigation, Autoplay]);

function DynamicSlider({ children, is_card }) {
  const sliderId = "";
  let slidesPerView = 3;
  let loopedSlides = 3;

  return (
    <div className="container">
      <div className="columns is-centered">
        <div className="column is-12 awards-slider">
          <>
            <div className="columns is-centered py-6">
              <div className="column is-12">
                <div className="slider-main">
                  <Swiper
                    css={SwiperCss}
                    spaceBetween={is_card ? 500 : 50}
                    autoplay
                    loop={true}
                    lazy={true}
                    loopedSlides={loopedSlides}
                    slidesPerView={slidesPerView}
                    breakpoints={{
                      300: {
                        slidesPerView: 1,
                        loopedSlides: 1,
                        spaceBetween: is_card ? 500 : 30,
                      },
                      1215: {
                        slidesPerView: 3,
                        loopedSlides: 3,
                        spaceBetween: is_card ? 500 : 40,
                      },
                      1400: {
                        slidesPerView: 3,
                        loopedSlides: 3,
                        spaceBetween: is_card ? 500 : 50,
                      },
                    }}
                    navigation={{
                      nextEl: `.swiper-${sliderId}-next`,
                      prevEl: `.swiper-${sliderId}-prev`,
                    }}
                  >
                    {children}
                  </Swiper>

                  <div
                    role="button"
                    name="Previous slide"
                    aria-label="Previous slide"
                    className={`swiper-button-prev swiper-${sliderId}-prev`}
                  ></div>
                  <div
                    role="button"
                    name="Next slide"
                    aria-label="Next slide"
                    className={`swiper-button-next swiper-${sliderId}-next`}
                  ></div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default DynamicSlider;

const SwiperCss = css`
  .swiper-slide {
    display: flex;
    justify-content: center;
  }

  @media only screen and (max-width: 1024px) {
    .swiper-wrapper {
      width: 840px !important;
    }
  }
`;
