import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { RichText } from "prismic-reactjs";
import Image from "../components/image";
import LocalizedLink from "../components/localizedLink";
import { LocaleContext } from "./layout";
import locales from "../../config/i18n";
import LangSwitcher from "../components/langSwitcher";
import linkResolver from "../utils/linkResolver";
import { openPopupWidget } from "react-calendly";

import { css } from "@emotion/core";
import { colors } from "../styles/styles";
import i18n from "../../config/i18n";

import onClickOutside from "react-onclickoutside";
import { logo } from "../../config/website";
import { buildRegisterURL, getFrontURL } from "../utils/utils";

// Dropdown Menu SCSS
import "../styles/header/dropdown_menu.scss";
import "../styles/header/submenu.scss";
// Header SCSS
import "../styles/header/header.scss";
import "../styles/header/addons.scss";
// Blue-Section SCSS
import "../styles/header/blue-section/icons-alignement.scss";
import "../styles/header/navbar/freetrial.scss";
import { SubMenu_SubElement } from "./subMenu_SubElement";
import { PopupRouter } from "./popupRouter";

//Varible important for the gestion of window during gatsby build use for google Analytics
const dataLayerw =
  typeof window !== `undefined`
    ? (window.dataLayer = window.dataLayer || [])
    : null;

const CalendlyButton = ({ url, prefill, pageSettings, utm, children }) => {
  const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm });

  return (
    <span id="calendly_button" onClick={onClick}>
      {children}
    </span>
  );
};

const isBrowser = typeof window !== "undefined";

function Header({ allHeadersAndFooters, allUseCasesPages }) {
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [subMenuOpened, setSubMenuOpened] = useState(false);
  Header.handleClickOutside = () => setSubMenuOpened(false);
  let { locale, location, alternateLanguages } = React.useContext(
    LocaleContext
  );
  locale = locale ? locale : "en-gb"; // fix: 404 doesnt know the locale
  const pathToHome =
    locales[locale].default === true ? "/" : `/${locales[locale].path}/`;

  const [isIconIaHovering, setIsIconIaHovering] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("gclid")) {
      document.cookie = "gclid=" + urlParams.get("gclid");
    }

    if (urlParams.get("utm_source")) {
      document.cookie = "utm_source=" + urlParams.get("utm_source");
    }

    if (urlParams.get("utm_medium")) {
      document.cookie = "utm_medium=" + urlParams.get("utm_medium");
    }

    if (urlParams.get("utm_campaign")) {
      document.cookie = "utm_campaign=" + urlParams.get("utm_campaign");
    }

    if (urlParams.get("utm_term")) {
      document.cookie = "utm_term=" + urlParams.get("utm_term");
    }

    if (urlParams.get("utm_content")) {
      document.cookie = "utm_content=" + urlParams.get("utm_content");
    }
    let lastScrollTop = 0;
    if (document.querySelector(".header")) {
      let header = document.querySelector(".header");
      window.addEventListener("scroll", function() {
        var scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > lastScrollTop && window.pageYOffset > 1730) {
          header.style.top = "-20.5rem";
        } else {
          header.style.top = "0";
        }
        lastScrollTop = scrollTop;
      });
    }
  });

  if (allHeadersAndFooters === undefined) return false; // fix bug previews/ doesn't know the header
  if (typeof allHeadersAndFooters === "string") return false; // fix bug previews/ doesn't know the header
  const headerData = allHeadersAndFooters?.prismic?.allHeaders.edges;
  let headerDataLang = headerData.filter(
    headerData => headerData.node._meta.lang === locale
  )

  let useCasesDataLang = []

  let useCasesData = null

  if (allUseCasesPages === undefined || typeof allUseCasesPages === "string") {
    
  } else {
    useCasesData = allUseCasesPages?.prismic?.allUsecasespages.edges
    useCasesDataLang = useCasesData.filter(
      useCasesData => useCasesData.node._meta.lang === locale
    )
  }

  headerDataLang = headerDataLang[0].node;

  const isHomepage =
    location.pathname === "/" ||
    location.pathname === `/${i18n[locale].path}` ||
    location.pathname === `/${i18n[locale].path}/`
      ? true
      : false;

  const navClassName = "navbar nav-background-blue";
  let menuWithSubElementsList = [];

  const createMenuWithSubElements = (titleName) => {
    const allMultipleLevelsMenu = headerDataLang.body.filter(
      (item) =>
        item.type === "menu_group_with_subgroup" &&
        item.primary.menu_group_with_sub_group_master_title[0].text ===
          titleName
    );
    let menus = [];
    allMultipleLevelsMenu.forEach((item) => {
      const value = titleName;

      const subMenu = {
        subMenuTitle: item.primary.menu_group_with_sub_group_name[0].text,
        subMenuData: [],
      };

      item.fields.forEach((itemSubMenu) => {
        subMenu.subMenuData.push({
          id: itemSubMenu.menu_group_with_sub_group_element_id[0].text,
          name: itemSubMenu.menu_group_with_sub_group_element_name[0].text,
          linkUrl:
            itemSubMenu?.menu_group_with_sub_group_element_link?._meta?.uid,
        });
      });

      const indexItem = menus.map((item) => item.title).indexOf(value);

      if (indexItem === -1) {
        menus.push({
          title: value,
          data: [subMenu],
        });
      } else {
        menus[indexItem].data.push(subMenu);
      }
    });
    return menus;
  };

  const toggleClass = (className) => {
    const element = document.querySelector(`.${className}`);
    if (element.classList.contains("activeSubMenu")) {
      element.classList.remove("activeSubMenu");
    } else {
      element.classList.add("activeSubMenu");
    }
  };

  return (
    <header id="header_main" css={headerCss}>
      {isHomepage && (
        <PopupRouter headerDataLang={headerDataLang} useCasesDataLang={useCasesDataLang} locale={locale}/>
      )}
      <div className="header">
        {headerDataLang.alert_message.length > 0 &&
          headerDataLang.alert_message[0].text !== "" && (
            <div className="ticker-wrap">
              <div className="ticker">
                <div className="ticker__item notification mb-0">
                  {/* <button aria-label="close alert" className="delete"></button> */}
                  {RichText.render(headerDataLang.alert_message, linkResolver)}
                </div>
              </div>
            </div>
          )}
        <nav className="blue-section">
          <div className="left-section">
            <div className="level-item">
              {headerDataLang.header_call_to_action.map((action) => {
                // if(action.header_call_to_action_id ==='appointment'){ return <CustomButton url={"https://calendly.com/monstock"} />}
                if (action.header_call_to_action_id === "appointment") {
                  return (
                    <CalendlyButton
                      key={action.header_call_to_action_id}
                      url={`https://calendly.com/monstock/${
                        locale === "fr-fr" ? "rdv" : "book"
                      }`}
                    >
                      <div
                        className="header__cta has-text-white pr-3"
                        css={{
                          cursor: "pointer",
                          ":hover": { color: "#e6e6e6 !important" },
                        }}
                      >
                        <span
                          className={
                            "has-background-white icon-" +
                            action.header_call_to_action_id.toLowerCase()
                          }
                        />
                        <div className="is-hidden-touch">
                          {action.header_call_to_action_name}
                        </div>
                      </div>
                    </CalendlyButton>
                  );
                }
                if (action.header_call_to_action_id === "phone") {
                  return (
                    <div id="contact-tel">
                      <a
                        key={action.header_call_to_action_id}
                        href={`tel:${action.header_call_to_action_name}`}
                        className="header__cta has-text-white pr-3"
                        id="contact-tel"
                      >
                        <span
                          className={
                            "has-background-white icon-" +
                            action.header_call_to_action_id.toLowerCase()
                          }
                          id="contact-tel"
                        />
                        <div className="is-hidden-touch" id="contact-tel">
                          {action.header_call_to_action_name}
                        </div>
                      </a>
                    </div>
                  );
                }
                if (action.header_call_to_action_id === "email") {
                  return (
                    <div id="contact-email">
                      <a
                        key={action.header_call_to_action_id}
                        href={`mailto:${action.header_call_to_action_name}`}
                        className="header__cta has-text-white pr-3"
                        id="contact-email"
                      >
                        <span
                          className={
                            "has-background-white icon-" +
                            action.header_call_to_action_id.toLowerCase()
                          }
                          id="contact-email"
                        />
                        <div className="is-hidden-touch" id="contact-email">
                          {action.header_call_to_action_name}
                        </div>
                      </a>
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="right-section">
            <div className="level-item">
              <p className="has-text-white is-hidden-touch">
                {RichText.asText(headerDataLang.header_social_title)}
              </p>
              {headerDataLang.header_social.map((social, i) => {
                return (
                  <a
                    key={social.header_social_id}
                    href={social.header_social_link.url}
                    className="header__social has-text-white"
                    target="_blank"
                  >
                    <span
                      className={
                        "icon-" + social.header_social_id.toLowerCase()
                      }
                      id={
                        i === 0
                          ? "linkedin-bt"
                          : i === 1
                          ? "facebook-bt"
                          : i === 2
                          ? "youtube-bt"
                          : "twitter-bt"
                      }
                    />
                    <span className="is-hidden">{social.header_social_id}</span>
                  </a>
                );
              })}
            </div>
          </div>
        </nav>

        <nav
          className={navClassName}
          role="navigation"
          aria-label="main navigation"
        >
          <div className="navbar-brand">
            <Link to={pathToHome}>
              <div className="logo">
                <img
                  className="logo-align"
                  src={headerDataLang.header_logo_image.url}
                  alt={headerDataLang.header_logo_image.alt}
                />
              </div>
            </Link>

            {headerDataLang.body.map((item) => {
              if (item.type === "menu_element") {
                if (item.primary.menu_element_id === "freetrial") {
                  const link = item.primary.menu_element_link;
                  if (link._linkType === "Link.web") {
                    if ((link.url = "https://cloud.monstock.net/#/register")) {
                      const menuItemClass =
                        "button is-rounded button__primary has-text-white";
                      //button a tracker
                      return (
                        <div id="trial-bt">
                          <a
                            key={item.primary.menu_element_id}
                            className={`${menuItemClass} freetrial-brand__mobile`}
                            id="trial-bt"
                            rel="nofollow external"
                            onClick={() => {
                              window.location = buildRegisterURL(
                                `${getFrontURL()}/#/register`
                              );
                              setIsOpenMobileMenu(false);
                            }}
                          >
                            {item.primary.menu_element_name}
                          </a>
                        </div>
                      );
                    }
                  }
                }
              }
            })}

            <div
              className="navbar-burger burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="menu"
              onClick={() => {
                setIsOpenMobileMenu(!isOpenMobileMenu);
              }}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </div>
          </div>

          <div
            id="menu"
            className="navbar-menu"
            css={{ display: isOpenMobileMenu ? "block" : "" }}
          >
            <div className="navbar-start">
              {headerDataLang.body.map((item) => {
                if (item.type === "menu_element") {
                  // simple menu element
                  let menuItemClass = "navbar-item";
                  if (item.primary.menu_element_id === "connexion") {
                    // if this is connexion item give bold class
                    menuItemClass =
                      "button is-rounded is-outlined is-link is-connexion";
                    return (
                      <div className="menu_element_item">
                        <a
                          key={item.primary.menu_element_id}
                          className={menuItemClass}
                          href={item.primary.menu_element_link.url}
                          onClick={() => setIsOpenMobileMenu(false)}
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                        >
                          {item.primary.menu_element_name}
                        </a>
                      </div>
                    );
                  }
                  if (item.primary.menu_element_id === "freetrial") {
                    // if this is free trial give btn primary class
                    menuItemClass =
                      "button is-rounded button__primary has-text-white";
                  }
                  const link = item.primary.menu_element_link;
                  if (link?._linkType === "Link.document") {
                    if (
                      item.primary.menu_element_name === "IA" ||
                      item.primary.menu_element_name === "AI"
                    ) {
                      return (
                        <LocalizedLink
                          key={item.primary.menu_element_id}
                          to={link._meta.uid}
                          onClick={() => setIsOpenMobileMenu(false)}
                          className={"navbar-item menu-item-with-icon"}
                          onMouseEnter={() => setIsIconIaHovering(true)}
                          onMouseLeave={() => setIsIconIaHovering(false)}
                        >
                          {isIconIaHovering ? (
                            <img
                              src={item.primary.menu_element_icon_hover.url}
                              style={{
                                height: `${item.primary.menu_element_icon_hover.dimensions.height}px`,
                                width: `${item.primary.menu_element_icon_hover.dimensions.width}px`,
                              }}
                            />
                          ) : (
                            <img
                              src={item.primary.menu_element_icon.url}
                              style={{
                                height: `${item.primary.menu_element_icon.dimensions.height}px`,
                                width: `${item.primary.menu_element_icon.dimensions.width}px`,
                              }}
                            />
                          )}
                          {item.primary.is_hidden &&
                            item.primary.menu_element_name}
                        </LocalizedLink>
                      );
                    } else {
                      return (
                        <LocalizedLink
                          key={item.primary.menu_element_id}
                          to={link._meta.uid}
                          className={menuItemClass}
                          onClick={() => setIsOpenMobileMenu(false)}
                        >
                          {item.primary.menu_element_name}
                        </LocalizedLink>
                      );
                    }
                  }
                  if (link?._linkType === "Link.web")
                    if ((link.url = "https://cloud.monstock.net/#/register")) {
                      //button a tracker
                      return (
                        <div id="trial-bt">
                          <a
                            key={item.primary.menu_element_id}
                            className={menuItemClass}
                            id="trial-bt"
                            rel="nofollow external"
                            onClick={() => {
                              window.location = buildRegisterURL(
                                `${getFrontURL()}/#/register`
                              );
                              setIsOpenMobileMenu(false);
                            }}
                          >
                            {item.primary.menu_element_name}
                          </a>
                        </div>
                      );
                    }
                  return (
                    <>
                      <div
                        className={"menu-item-with-icon"}
                        onMouseEnter={() => setIsIconIaHovering(true)}
                        onMouseLeave={() => setIsIconIaHovering(false)}
                      >
                        {isIconIaHovering ? (
                          <img
                            src={item.primary.menu_element_icon_hover.url}
                            style={{
                              height: `${item.primary.menu_element_icon_hover.dimensions.height}px`,
                              width: `${item.primary.menu_element_icon_hover.dimensions.width}px`,
                            }}
                          />
                        ) : (
                          <img
                            src={item.primary.menu_element_icon.url}
                            style={{
                              height: `${item.primary.menu_element_icon.dimensions.height}px`,
                              width: `${item.primary.menu_element_icon.dimensions.width}px`,
                            }}
                          />
                        )}
                        <a
                          key={item.primary.menu_element_id}
                          href={link && link.url}
                          onClick={() => setIsOpenMobileMenu(false)}
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                        >
                          {item.primary.is_hidden &&
                            item.primary.menu_element_name}
                        </a>
                      </div>
                    </>
                  );
                } else if (item.type === "menu_element_with_subelements") {
                  return (
                    item.primary.is_hidden && (
                      <div
                        key={item.primary.menu_group_id}
                        className="navbar-item has-dropdown is-hoverable has-text-weight-semibold"
                        onMouseEnter={() => {
                          setSubMenuOpened(item.primary.menu_group_id);
                          if (
                            item.primary.menu_group_id === "ia" ||
                            item.primary.menu_group_id === "ai"
                          ) {
                            setIsIconIaHovering(true);
                          }
                        }}
                        onMouseLeave={() => {
                          setSubMenuOpened(false);
                          setIsIconIaHovering(false);
                        }}
                      >
                        <a className="navbar-link">
                          {item.primary.is_hidden &&
                          (item.primary.menu_group_id === "ia" ||
                            (item.primary.menu_group_id === "ai" &&
                              !isOpenMobileMenu)) ? (
                            <>
                              {isIconIaHovering ? (
                                <img
                                  src="https://monstock.cdn.prismic.io/monstock/Z1gObpbqstJ98QA7_Iconeetoilewhite-1-.svg"
                                  alt="logo ia hover"
                                  style={{ marginRight: "5px", height: "24px" }}
                                />
                              ) : (
                                <img
                                  src="https://monstock.cdn.prismic.io/monstock/Z08Vm5bqstJ97_bO_Iconeetoileorange.svg"
                                  alt="logo ia"
                                  style={{ marginRight: "5px", height: "24px" }}
                                />
                              )}
                              {item.primary.menu_group_name}
                            </>
                          ) : (
                            <>{item.primary.menu_group_name}</>
                          )}
                        </a>

                        <div
                          className={`navbar-dropdown ${
                            isOpenMobileMenu ? "navbar__dropdown-mobile" : ""
                          }`}
                          style={{
                            display:
                              subMenuOpened === item.primary.menu_group_id
                                ? "block"
                                : "none",
                          }}
                        >
                          {item.fields.map((element) => {
                            if (
                              element.menu_group_element_link?._linkType ===
                              "Link.document"
                            ) {
                              return (
                                <LocalizedLink
                                  key={element.menu_group_element_id}
                                  to={element.menu_group_element_link._meta.uid}
                                  className="navbar-item"
                                  onClick={() => {
                                    setIsOpenMobileMenu(false);
                                    setSubMenuOpened(false);
                                  }}
                                >
                                  {element.menu_group_element_name}
                                </LocalizedLink>
                              );
                            }
                            if (
                              element.menu_group_element_link?._linkType ===
                              "Link.web"
                            )
                              return (
                                <a
                                  key={element.menu_group_element_id}
                                  className="navbar-item"
                                  href={element.menu_group_element_link.url}
                                  onClick={() => {
                                    setIsOpenMobileMenu(false);
                                    setSubMenuOpened(false);
                                  }}
                                  target="_blank"
                                  rel="noopener noreferrer nofollow"
                                >
                                  {item.primary.is_hidden &&
                                    element.menu_group_element_name}
                                </a>
                              );
                            return true;
                          })}
                        </div>
                      </div>
                    )
                  );
                } else if (item.type === "menu_group_with_subgroup") {
                  const titleName =
                    item.primary.menu_group_with_sub_group_master_title[0].text;
                  if (
                    menuWithSubElementsList.find((item) => item === titleName)
                  )
                    return;
                  menuWithSubElementsList.push(titleName);

                  let screenWidth = 0;

                  if (isBrowser) {
                    screenWidth = window.innerWidth;
                  }

                  return (
                    <>
                      {createMenuWithSubElements(titleName).map((menu) => (
                        <div
                          className={`navbar-item with-sub-group navbar-item has-dropdown is-hoverable has-text-weight-semibold`}
                          onClick={() => toggleClass(menu.title)}
                          onMouseEnter={() =>
                            setSubMenuOpened(
                              item.primary.menu_group_with_sub_group_id[0].text
                            )
                          }
                          onMouseLeave={() => setSubMenuOpened(false)}
                        >
                          <a className="navbar-link">{menu.title}</a>
                          <div
                            className={`menu-dropdown-with-sub-group ${menu.title}`}
                            style={{
                              display:
                                subMenuOpened ===
                                item.primary.menu_group_with_sub_group_id[0]
                                  .text
                                  ? "flex"
                                  : "none",
                            }}
                          >
                            {screenWidth <= 1023 ? (
                              menu.data.map((subMenu) => (
                                <div className="subMenu">
                                  <span>{subMenu.subMenuTitle}</span>
                                  {subMenu.subMenuData.map((item) =>
                                    item.id !== "Others" ? (
                                      <a
                                        href={
                                          item.linkUrl &&
                                          `/${i18n[locale].path}/${item.linkUrl}`
                                        }
                                        className="subMenuItem"
                                      >
                                        {item.name}
                                      </a>
                                    ) : (
                                      <a href className="subMenuItemOthers">
                                        {item.name}
                                      </a>
                                    )
                                  )}
                                </div>
                              ))
                            ) : (
                              <SubMenu_SubElement
                                menuData={menu.data}
                                locale={locale}
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </>
                  );
                }
                return true;
              })}
            </div>

            <div className="navbar-end">
              <LangSwitcher flags={allHeadersAndFooters.allFile} />
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}
const onClickOutsideConfig = {
  handleClickOutside: () => Header.handleClickOutside,
};
export default onClickOutside(Header, onClickOutsideConfig);
Header.propTypes = {
  allHeadersAndFooters: PropTypes.object.isRequired,
};

const headerCss = css`
  .with-sub-group {
    cursor: pointer;
    position: relative;
    z-index: 2;
    & > span {
      font-size: 0.9rem;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 1499px) {
    .subMenu {
      display: flex;
      flex-direction: column;
      padding: 10px;
      min-width: 103px;

      & > span {
        margin-bottom: 10px;
        color: #5769a4;
        font-size: 0.9rem;
        font-weight: 600;
        text-decoration: underline;
        cursor: text;
      }
    }
  }

  @media screen and (max-width: 1415px) {
    .subMenu {
      display: flex;
      flex-direction: column;
      padding: 10px;
      & > span {
        margin-bottom: 10px;
        color: #5769a4;
        font-size: 0.9rem;
        font-weight: 600;
        text-decoration: underline;
        cursor: text;
      }
    }
  }

  @media screen and (min-width: 1454px) {
    .menu-dropdown-with-sub-group {
      left: 292%;
    }
  }

  .subMenuItem {
    padding: 10px;
    border-bottom: 1px solid rgba(87, 105, 164, 0.1);
    &:hover {
      background-image: ${colors.orangeGradient};
      border-radius: 7px;
      color: #fff;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .subMenuItemOthers {
    padding: 10px;
    border-bottom: 1px solid rgba(87, 105, 164, 0.1);
    &:hover {
      border-radius: 7px;
      cursor: auto;
      color: #5769a4;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .menu-dropdown-with-sub-group.activeSubMenu {
    display: flex;
  }

  .dropdown__lang {
    .dropdown-menu {
      left: auto;
      right: 0;
    }
    .button {
      &.button {
        border: 1px solid ${colors.blue};
        padding-right: 20px;
      }
      span {
        display: none;
      }
      .icon {
        padding-left: 0;
      }
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 3rem;
    background-color: #000;
    padding-left: 100%;
    box-sizing: content-box;

    .notification {
      border-radius: 0;
    }

    .ticker {
      display: inline-block;
      height: 3rem;
      line-height: 3rem;
      white-space: nowrap;
      padding-right: 100%;
      box-sizing: content-box;

      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-name: ticker;
      animation-name: ticker;
      -webkit-animation-duration: 20s;
      animation-duration: 20s;

      &__item {
        background-color: #000;
        display: inline-block;
        padding: 0 2rem;
        color: white;
      }
    }
  }

  .navbar {
    padding: 20px;

    .logo {
      width: 180px;
    }

    .navbar-burger:hover {
      background-color: ${colors.blueLightOne};
      border-radius: 9px;
    }

    .gatsby-image-wrapper {
      display: flex;
    }

    .navbar-end {
      align-items: center;
    }

    .is-connexion {
      margin-right: 15px;
    }

    .navbar-item {
      .navbar-link {
        &:hover,
        &:focus,
        &:focus-within,
        &:active {
          color: #fff;
        }
      }

      &:hover {
        background-image: ${colors.orangeGradient};
        border-radius: 79px;
        color: #fff;

        .navbar-link {
          background: ${colors.orangeGradient};
          border-radius: 20px;
          color: #fff;
          height: 40px;
          margin-top: 0;
        }
      }
    }
  }

  .navbar.nav-background-blue {
    background-color: ${colors.blueBg};
  }

  .navbar.nav-background-transparent {
    background-color: transparent;
    position: absolute;
    width: 100%;
  }

  .navbar-dropdown {
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    border-top: 0px;
    padding: 10px;

    &.navbar__dropdown-mobile {
      left: 0;
      transform: none;
      background: ${colors.zircon};

      .navbar-item:hover {
        background-image: none;
        color: ${colors.orange};
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      .navbar-item:not(:last-child):hover {
        border-bottom-color: ${colors.blueLightOne};
      }
      &::after {
        left: 30px;
        border-bottom-color: ${colors.zircon};
        margin-top: 32px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: #fff;
      border-top: 0;
      margin-left: -10px;
      margin-top: -10px;
    }

    .navbar-item {
      border-bottom: 1px solid ${colors.blueLightOne};

      &:last-child {
        border-bottom: 1px solid transparent;
      }

      &:hover,
      &:focus {
        background-image: ${colors.orangeGradient};
        color: #fff;
        border-radius: 7px;
        border-bottom-color: transparent;
        border-top-color: transparent;
      }
    }
  }

  .navbar {
    flex-wrap: wrap;

    &.is-transparent {
      background-color: transparent;
      background-image: none;
    }
  }

  .navbar-link:not(.is-arrowless)::after {
    border-color: ${colors.blue};
  }

  .navbar-start {
    align-items: center;
  }

  .navbar-menu a {
    font-size: 1rem;
    font-weight: 600;
  }

  .header__cta {
    display: flex;
    span {
      color: ${colors.blue};
      height: 27px;
      width: 27px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-right: 12px;
    }
  }

  .header__social {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 27px;
    width: 27px;
    height: 27px;
    border: solid #fff 2px;
    border-radius: 50%;
    margin: 0 5px;
  }

  @media (min-width: 1000px) and (max-width: 1600px) {
    .navbar-brand a {
      display: flex;
      align-items: center;
    }

    .navbar-brand {
      align-items: center;
    }

    .navbar-menu {
      border-radius: 10px;
    }

    .dropdown__lang {
      .dropdown-menu {
        left: 0;
        right: auto;
      }
    }
    .navbar-menu a {
      font-size: 1rem;
    }
    .logo-align {
      margin-top: 0.5rem;
    }
  }

  @media (max-width: 1023px) {
    .navbar-brand a {
      display: flex;
      align-items: center;
    }

    .navbar-brand {
      align-items: center;
    }

    .navbar-end,
    .navbar-start .button {
      margin: 10px;
    }

    .navbar-menu {
      border-radius: 10px;
    }

    .dropdown__lang {
      .dropdown-menu {
        left: 0;
        right: auto;
      }
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1453px) {
    .navbar-menu a {
      font-size: 10px;
      font-weight: 600;
    }
    .navbar .logo {
      width: 140px;
    }
    .dropdown__lang .dropdown-menu,
    .button {
      font-size: 0.8rem;
    }
  }

  @media screen and (min-width: 1024px) {
    .navbar-menu {
      flex-grow: 0;
      flex-shrink: 1;
      flex-wrap: wrap;
    }
    .navbar,
    .navbar-menu,
    .navbar-start,
    .navbar-end {
      align-items: center;
      display: flex;
    }
    .navbar {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
    .navbar-brand,
    .navbar-item,
    .navbar-start {
      margin-right: 18px;
    }
    .navbar-dropdown {
      .navbar-item {
        margin-right: 0;
      }
    }
  }
`;
