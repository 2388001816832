import React from "react";
import { RichText } from "prismic-reactjs";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";

import "./VerbatimText.scss";

function VerbatimText({ image_above, text_info, url, alt }) {
  return (
    <div
      className={
        (image_above ? "verbatimtext is-above" : "verbatimtext")
      }
    >
      <div className="verbatimtext-richtext">
        <RichText render={text_info} />
      </div>

      <div className="verbatimtext-image">
        <img src={url.split("?")[0]} alt={alt} />
      </div>
    </div>
  );
}

export default VerbatimText;
