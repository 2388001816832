import React from "react";
import { RichText } from "prismic-reactjs";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";

import "./Card.scss";

function Card({ image_above, rate, text_info, url, alt }) {
  return (
    <div className={image_above ? "card-component is-above" : "card-component"}>
      <div className="card-component-container">
        <div className="card-component-container-box">
          {rate && (
            <Box component="fieldset" mb={3} borderColor="transparent">
              <Rating name="read-only" value={rate} precision={0.5} readOnly />
            </Box>
          )}
        </div>

        <div className="card-component-container-text">
          <div className="card-component-container-text-richtexttitle">
            <RichText render={text_info} />
          </div>

          <div className="card-component-container-text-richtext">
            <RichText render={text_info} />
          </div>
        </div>

        <div className="card-component-container-image">
          <img src={url.split("?")[0]} alt={alt} />
        </div>
      </div>
    </div>
  );
}

export default Card;
