import React from "react";
import { RichText } from "prismic-reactjs";
import { SwiperSlide } from "swiper/react";

import Card from "../../components/Card/Card";
import VerbatimText from "../../components/VerbatimText/VerbatimText";

import "./SectionVerbatim.scss";
import DynamicSlider from "../../components/DynamicSlider/DynamicSlider";

function SectionVerbatim({ componentData }) {
  return (
    <section
      style={{ backgroundColor: componentData.primary.section_color }}
      className="section-verbatim"
    >
      <div>
        <div className="section-verbatim-title">
          <RichText render={componentData.primary.section_title} />
        </div>

        {/* ----CAROUSEL---- */}
        {componentData.primary.is_carousel ? (
          <>
            <div className="section-verbatim-container-card">
              {componentData.primary.is_card ? (
                <DynamicSlider is_card="true">
                  {componentData.fields.map((card, i) => {
                    return (
                      <SwiperSlide style={{marginRight: "0px"}} key={i}>
                        <Card
                          key={i}
                          image_above={card.image_above}
                          rate={card.rate}
                          text_info={card.text_info}
                          url={card.image.url}
                          alt={card.image.alt}
                        />
                      </SwiperSlide>
                    );
                  })}
                </DynamicSlider>
              ) : (
                <DynamicSlider>
                  {componentData.fields.map((verbatim, i) => {
                    return (
                      <SwiperSlide style={{marginRight: "0px"}} key={i}>
                        <VerbatimText
                          key={i}
                          image_above={verbatim.image_above}
                          text_info={verbatim.text_info}
                          url={verbatim.image.url}
                          alt={verbatim.image.alt}
                        />
                      </SwiperSlide>
                    );
                  })}
                </DynamicSlider>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="section-verbatim-container">
              {componentData.primary.is_card
                ? componentData.fields.map((card, i) => {
                    return (
                      <Card
                        key={i}
                        image_above={card.image_above}
                        rate={card.rate}
                        text_info={card.text_info}
                        url={card.image.url}
                        alt={card.image.alt}
                      />
                    );
                  })
                : componentData.fields.map((verbatim, i) => {
                    return (
                      <VerbatimText
                        key={i}
                        image_above={verbatim.image_above}
                        text_info={verbatim.text_info}
                        url={verbatim.image.url}
                        alt={verbatim.image.alt}
                      />
                    );
                  })}
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export const SectionVerbatimQuery = graphql`
  fragment SectionVerbatim on PRISMIC_Dynamic_page_builderBodySection_verbatim {
    type
    fields {
      rate
      image_above
      image
      text_info
    }
    primary {
      section_color
      section_title
      is_card
      is_carousel
    }
  }
`;

export default SectionVerbatim;
